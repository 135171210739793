import "webrtc-adapter";
import RTCMultiConnection from "rtcmulticonnection";

import { v4 as uuid } from "uuid";

const ICE_SERVERS = {
  urls: [
    "stun:stun.l.google.com:19302",
    "stun:stun1.l.google.com:19302",
    "stun:stun2.l.google.com:19302",
    "stun:stun3.l.google.com:19302",
    "stun:stun4.l.google.com:19302",
    "stun:stun.ekiga.net",
    "stun:stun.ideasip.com",
    "stun:stun.schlund.de",
    "stun:stun.stunprotocol.org:3478",
    "stun:stun.voiparound.com",
    "stun:stun.voipbuster.com",
    "stun:stun.voipstunt.com",
  ],
};

/**
 * @callback onStreamCallback a function that is called when the connection stream is established.
 *
 * @param {RTCMultiConnection} connection the underlying RTC connection
 * @param {object} event the connection event
 */

/**
 * @callback onStreamEndCallback a function that is called when the connection stream is ended.
 *
 * @param {RTCMultiConnection} connection the underlying RTC connection
 * @param {object} event the connection event
 */

/**
 * @callback onMediaErrorCallback a function that is called when a media error happens.
 *
 * @param {RTCMultiConnection} connection the underlying RTC connection
 * @param {object} event the connection event
 * @param {object} constraints media constraints
 */

/**
 * A `RTCMultiConnection`-based screen-sharing connection.
 */
export class ScreenShareConnection {
  /**
   * Creates a new screen sharing connection.
   *
   * @param {object} config screen share connection configuration
   * @param {HTMLElement} config.videosContainer container into which the RTC video is embedded
   * @param {string} config.signalServerUrl URL of the signaling server
   * @param {number} config.maxParticipantsAllowed number of maximum participants
   * @param {onStreamCallback} config.onStream a function that is called when the connection stream is established
   * @param {onStreamEndCallback} config.onStreamEnded a function that is called when the connection stream is ended
   * @param {onMediaErrorCallback} config.onMediaError a function that is called when a media error happens
   */
  constructor({
    videosContainer,
    signalServerUrl = "https://muazkhan.com:9001/",
    maxParticipantsAllowed = 2,
    onStream = undefined,
    onStreamEnded = undefined,
    onMediaError = undefined,
  } = {}) {
    const connection = new RTCMultiConnection();
    connection.iceServers = [ICE_SERVERS];
    connection.maxParticipantsAllowed = maxParticipantsAllowed;
    connection.socketURL = signalServerUrl;
    connection.session = { screen: true, oneway: true };
    connection.socketMessageEvent = "ScreenShare";
    connection.sdpConstraints.mandatory = { OfferToReceiveAudio: false, OfferToReceiveVideo: false };
    if (videosContainer) {
      connection.videosContainer = videosContainer;
    } else {
      connection.autoCreateMediaElement = false;
    }
    if (onStream) connection.onstream = (e) => onStream(connection, e);
    if (onStreamEnded) connection.onstreamended = (e) => onStreamEnded(connection, e);
    if (onMediaError) connection.onMediaError = (e, constraints) => onMediaError(connection, e, constraints);
    this.connection = connection;
  }

  /**
   * Starts a new screen sharing session and returns the used Room ID.
   */
  startSharing() {
    const roomId = uuid();
    console.debug(`Starting sharing screen for room '${roomId}'.`);
    this.connection.open(roomId, () => console.info(`Screen sharing session for room '${roomId}' started.`));
    return roomId;
  }

  /**
   * Stops the screen sharing session if any was started.
   */
  stopSharing() {
    console.debug(`Stopping screen share...`);
    // disconnect with all users
    const connection = this.connection;
    connection.getAllParticipants().forEach((pid) => connection.disconnectWith(pid));

    // stop all local cameras
    connection.attachStreams.forEach((localStream) => localStream.stop());

    // close socket.io connection
    connection.closeSocket();
  }
}

export default ScreenShareConnection;
